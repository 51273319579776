import Vue from "vue"
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import formulaire from "./formulaire.vue"



$(() => {
    let idFormu = "#formulaireAdhesion";
    if ($(idFormu).length > 0) {
        new Vue({
            el: idFormu,
            components: {
                formulaireAdhesion:formulaire,
            },
        });
    }
})
