import $ from "jquery";

import "bootstrap";
import "jquery-validation";
import "jquery-validation-unobtrusive";
import "jquery-validation/dist/localization/messages_fr";

$.validator.setDefaults({
    errorElement: "span",
    errorClass: "text-danger is-invalid",
    highlight: function (element) {
        let $element = $(element);
        $element.addClass("is-invalid");

    },
    unhighlight: function (element) {
        let $element = $(element);

            $element.removeClass("is-invalid");
    },
    errorPlacement: function (error, element) {
        if (element.parent(".input-group").length) {
            error.insertAfter(element.parent());
        } else {
            error.insertAfter(element);
        }
    },
});
