<template>
    <div>
        <iframe class="col-12 h-100" v-if="valide" frameBorder="0" id="paiementProxy" name="paiementProxy" :src="src"></iframe>
        <span v-if="!valide"> Merci de renseigner vos informations de paiement avant de procéder au règlement.</span>

        <!--:src="src"-->
    </div>
</template>
<script>

    export default {
        props: ['pays', 'ville', 'adresse', 'cp', 'tarif', 'entreprise'],
        data() {
            return {
                src: ""
            }
        },
        mounted() {
            this.getsrc();
        },
        methods: {
            getsrc: function () {
                var self = this;
                $.ajax({
                    url: "/Home/PaiementSrc",
                    type: "GET",
                    data: {
                        adr: this.adresse,
                        ville: this.ville,
                        pays: this.pays,
                        cp: this.cp,
                        tarif: this.tarif,
                        entreprise: this.entreprise
                    },
                    async: false
                }).done(data => {
                    self.src = data.src;
                })
            }
        },
        computed: {
            valide() {
                return this.pays != null && this.ville != null && this.adresse != null && this.cp != null && this.tarif != null
            }
        }
    }
</script>
