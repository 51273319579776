export function addAntiforgeryToken(data: any) {
    data.__RequestVerificationToken = (<HTMLInputElement>(
        document.getElementsByName("__RequestVerificationToken")[0]
    )).value;
    return data;
}

$(".readhesion-header").on("click", function (e) {
    let divReadhesion = $(".readhesion");
    
    let divReadhesionContentOpened = $(".readhesion-content-opened");
    let divReadhesionContentClosed = $(".readhesion-content-closed");
    
    let divReadhesionLogoOpen = $(".readhesion-logo-open");
    let divReadhesionLogoClose = $(".readhesion-logo-close");

    // Si la div est fermée
    if (divReadhesion.hasClass("close")) {
        // Ouverture de la div
        divReadhesion.removeClass("close");
        divReadhesion.addClass("open");

        // Affichage du contenu quand la div est ouverte
        divReadhesionContentClosed.addClass("d-none");
        divReadhesionContentOpened.removeClass("d-none");

        // Changement de la flèche
        divReadhesionLogoOpen.addClass("d-none");
        divReadhesionLogoClose.removeClass("d-none");
        
        return;
    }

    // Si la div est ouverte
    if (divReadhesion.hasClass("open")) {
        // Fermeture de la div
        divReadhesion.removeClass("open");
        divReadhesion.addClass("close");

        // Affichage du contenu quand la div est fermée
        divReadhesionContentOpened.addClass("d-none");
        divReadhesionContentClosed.removeClass("d-none");

        // Changement de la flèche
        divReadhesionLogoClose.addClass("d-none");
        divReadhesionLogoOpen.removeClass("d-none");

        return;
    }
});
