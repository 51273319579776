<template>
    <div class="contact-fields mb-4">
        <div class="title">Contact optionnel</div>
        <hr class="hr1">
        <div class="row g-4 mb-4">
            <div class="col-sm">
                <ValidationProvider name="Nom" rules="required" v-slot="{ errors }">
                    <div class="form">
                        <input type="text" v-model="value.Nom" name="Nom *" @input="input" autocomplete="off" required>
                        <label for="Nom" class="label-name">
                            <span class="content-name">Nom *</span>
                        </label>
                    </div>
                    <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
            <div class="col-sm">
                <ValidationProvider name="Prenom" rules="required" v-slot="{ errors }">
                    <div class="form">
                        <input type="text" v-model="value.Prenom" name="Prénom *" @input="input" autocomplete="off" required>
                        <label for="Prenom" class="label-name">
                            <span class="content-name">Prénom *</span>
                        </label>
                    </div>
                    <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
            <div class="col-sm">
                <ValidationProvider name="Fonction" rules="required" v-slot="{ errors }">
                    <div class="form">
                        <input type="text" v-model="value.PosteOccupe" name="Fonction *" @input="input" autocomplete="off" required>
                        <label for="Fonction" class="label-name">
                            <span class="content-name">Fonction *</span>
                        </label>
                    </div>
                    <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>
        <div class="row g-4 mb-4">
            <div class="col-sm">
                <ValidationProvider name="Mail" rules="required|email" v-slot="{ errors }">
                    <div class="form">
                        <input type="text" v-model="value.Mail" name="Mail *" @input="input" autocomplete="off" required>
                        <label for="Mail" class="label-name">
                            <span class="content-name">Mail *</span>
                        </label>
                    </div>
                    <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
            <div class="col-sm">
                <ValidationProvider name="Téléphone" rules="required|numeric|phone" v-slot="{ errors }">
                    <div class="form">
                        <input type="text" v-model="value.Telephone" name="Téléphone" @input="input" autocomplete="off" required>
                        <label for="Téléphone" class="label-name">
                            <span class="content-name">Téléphone *</span>
                        </label>
                    </div>
                    <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
            <div class="col-sm">
                <ValidationProvider name="Mobile" rules="required|numeric|phone" v-slot="{ errors }">
                    <div class="form">
                        <input type="text" v-model="value.Mobile" name="Mobile" @input="input" autocomplete="off" required>
                        <label for="Mobile" class="label-name">
                            <span class="content-name">Mobile *</span>
                        </label>
                    </div>
                    <span class="error">{{ errors[0] }}</span>
                </ValidationProvider></div>
        </div>
        <button @click="remove" class="btn removecontact-option" type="button">Retirer le contact optionnel</button>
    </div>
</template>
<script>
    import {FormulaireInscriptionContact} from "../../Models/FormulaireInscriptionContact";

    import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
    import { required, email, numeric } from 'vee-validate/dist/rules';


    // No message specified.
    extend('email', {
        ...email,
        message: 'Le mail est invalide'
    });

    // Override the default message.
    extend('required', {
        ...required,
        message: 'Ce champ est obligatoire'
    });

    extend('naf', {
        validate: CodeNaf => {
            return /^(\d{2}\.{1}\d{2}[A-Z]{1})$/.test(CodeNaf);
        },
        message: 'CodeNaf invalide'
    });


    extend('check', {
        ...required,
        message: 'Autorisation obligatoire pour continuer'
    });

    extend('radiobox', {
        ...required,
        message: 'Autorisation obligatoire pour continuer'
    });

    extend('cp', {
        validate: Cp => {
            return Cp.length == 5;
        },
        message: 'Ce champs doit contenir 5 chiffres'
    });

    extend('numeric', {
        ...numeric,
        message: 'Ce champ doit contenir des nombres'
    });


    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },
        props:{
            value: {
                type: FormulaireInscriptionContact,
                required: true
            }
        },

        methods: {
            remove() {
                this.$emit("remove", this.value);
            },
            input() {
                this.$emit('input', this.value);
            },
        }
    }
</script>
