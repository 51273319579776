





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { FormulaireInscription } from "../Models/FormulaireInscription";
import { addAntiforgeryToken } from "../utilities";
import { FormulaireInscriptionContact } from "../Models/FormulaireInscriptionContact";
import contact from "./Components/contact.vue";
import paiement from "./Components/paiement.vue";
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email, numeric } from 'vee-validate/dist/rules';

// No message specified.
extend('email', {
    ...email,
    message: 'Le mail est invalide'
});

// Override the default message.
extend('required', {
    ...required,
    message: 'Ce champ est obligatoire'
});

extend('cgv', {
    validate: cgv => {
        return cgv;
    },
    message: 'La validation des CGV est nécessaire'
});

extend('collecte', {
    validate: collecte => {
        return collecte;
    },
    message: 'Cette autorisation de collecte est nécessaire'
});

extend('url', {
    validate: SiteInternet => {
        if (SiteInternet && SiteInternet !== "") {
            return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(SiteInternet);
        }

        return true; // Si pas de site, pas grave
    },
    message: 'URL invalide',
});

extend('naf', {
    validate: CodeNaf => {
        return /^(\d{2}\d{2}[A-Z]{1})$/.test(CodeNaf);
    },
    message: 'Code Naf invalide (format : 9999A)'
});

extend('check', {
    ...required,
    message: 'Autorisation obligatoire pour continuer'
});

extend('radiobox', {
    ...required,
    message: 'Autorisation obligatoire pour continuer'
});

extend('cp', {
    validate: Cp => {
        return Cp.toString().length == 5;
    },
    message: 'Ce champs doit contenir 5 chiffres'
});

extend('siret', {
    validate: Siret => {
        return Siret.toString().length == 14;
    },
    message: 'Ce champs doit contenir 14 chiffres'
});
extend('phone', {
    validate: Phone => {
        return Phone.toString().length == 10;
    },
    message: 'Ce champs doit contenir 10 chiffres'
});

extend('numeric', {
    ...numeric,
    message: 'Ce champ doit être numérique'
});



export default ({

    components: {
        contact,
        ValidationProvider,
        ValidationObserver,
        paiement
    },
    props: {
        listTailleSociete: {
            type: Array,
            default: () => []
        },
        listTypeActivites: {
            type: Array,
            default: () => []
        },
        listProfils: {
            type: Array,
            default: () => []
        },
        listTarifs: {
            type: Array,
            default: () => []
        },
        listIndicatifs: {
            type: Array,
            default: () => []
        },
        societe: {
            type: Object as () => FormulaireInscription
        },
        remerciement: {
            type: String,
        }
    },
    data() {
        return {
            data: this.societe ? this.societe : new FormulaireInscription(),
            adhesionCroiseeOpen: false,
            step: 1,
            totalsteps: 3,
            image: this.societe && this.societe.ParutionOption === true ? this.societe.Logo : null,
            AdresseFactOption: 0,
            message: null,
            traitement: true,
            redirection: this.remerciement,
        };
    },
    watch: {
        'data.IdProfil': function (newValue) {
            let acceptedStatuts = ["8", "9"];

            if (acceptedStatuts.includes(newValue.toString())) {
                this.adhesionCroiseeOpen = true;
            } else {
                this.adhesionCroiseeOpen = false;
            }
        },
        AdresseFactOption: function (newvalue) {
            if (newvalue == 0) {
                this.data.CountryFact = "";
                this.data.CityFact = "";
                this.data.AdresseFact = "";
                this.data.CpFacturation = "";

            } else {
                this.data.CountryFact = this.data.Country;
                this.data.CityFact = this.data.City;
                this.data.AdresseFact = this.data.AdresseSiegeSocial;
                this.data.CpFacturation = this.data.Cp;
            }
        }
    },
    computed: {
        montant() {
            return this.tarif() + "EUR";
        },
        tarif() {
            if (this.data.AdhesionCroisee) {
                this.data.Tarif = 0;
                return 0;
            }

            if (!this.listTarifs || !this.listTarifs.length || !this.data.IdTaille || !this.data.IdProfil) {
                this.data.Tarif = 0;
                return null;
            }
            
            let typeProfil = this.data.IdProfil > 2 ? 2: this.data.IdProfil;

            let tarif = this.listTarifs.find(_ => _.taille_societe_id.toString() === this.data.IdTaille.toString() && _.profil_id.toString() === typeProfil.toString());
            if (!tarif) {
                this.data.Tarif = 0;
                return null;
            }

            this.data.Tarif = tarif.value_ttc;

            return tarif.value_ttc;
        },
    },
    mounted() {
        if (this.societe) {
            this.data.Standard = this.data.NumeroStandard;

            localStorage.setItem('formulaire', JSON.stringify(this.data));
        } else {
            let previousData = localStorage.getItem("formulaire");
            if (previousData) {
                this.data = JSON.parse(previousData);
            } else {
                localStorage.setItem('formulaire', JSON.stringify(new FormulaireInscription()));
            }
        }

        setInterval(this.getNow, 1000);
        setInterval(this.priceDate, 1000);
    },

    methods: {
        scrollError() {
            if ($(".error:not(:empty)").length) {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(".error:not(:empty)").offset().top - 100 + 'px'
                }, 200);
            }
        },
        nextstep() {
            if (this.step <= 3) {
                localStorage.setItem('formulaire', JSON.stringify(this.data));
            }

            if (this.step == 1) {
                let go = false;

                let data = { model: this.data, readhesion: !!this.societe };

                $.ajax({
                    url: "/Formulaire/SaveSociete",
                    type: "POST",
                    data: addAntiforgeryToken(data),
                    async: false
                }).done(data => {
                    if (!data.message) {
                        this.message = null;
                        go = true;
                    } else {
                        this.message = data.message;
                    }
                })

                if (!go) {
                    return;
                }
            }
            
            if (this.step == 2) {
                let go = false;

                let data = { model: this.data, readhesion: !!this.societe };

                $.ajax({
                    url: "/Formulaire/SaveContact",
                    type: "POST",
                    data: addAntiforgeryToken(data),
                    async: false
                }).done(data => {
                        go = true;
                })

                if (!go) {
                    return;
                }
            }

            this.step++;
            this.$nextTick(() => {
                document.documentElement.scrollTop = 640;
            });
        },
        prevstep() {
            this.step--;
            this.$nextTick(() => {
                document.documentElement.scrollTop = 640;
            });
        },

        addContact() {
            this.data.Contacts.push(new FormulaireInscriptionContact())
        },

        removeContact(contact: FormulaireInscriptionContact) {
            this.data.Contacts = this.data.Contacts.filter(_ => _ != contact);
        },

        sendFormulaireAdhesion() {
            this.data.Logo = this.image;
            this.step++;
            $.ajax({
                url: "/Formulaire/Post",
                type: "POST",
                data: addAntiforgeryToken(this.data)
            }).done(data => {
                if(data.idTransaction){
                    this.data.IdDossier = data.idTransaction;
                }
                localStorage.setItem('formulaire', JSON.stringify(new FormulaireInscription()));
                this.traitement = false;
                this.message = null;

                /*AJout d'un evénement de tracking google analytics à la validation de la transaction*/
                gtag('event', 'formulaire', {'event_category' : 'contact','event_label' : 'Devenir adhérent'});
                
            })

        },
        ContactDirig() {
            if (this.showContact == 0 || this.showContact == 2 || this.showContact == 3) {
                this.showContact = 1;
            }
            else if (this.showContact == 1) {
                this.showContact = 0;
            }
        },
        ContactAdmin() {
            if (this.showContact == 0 || this.showContact == 1 || this.showContact == 3) {
                this.showContact = 2;
            }
            else if (this.showContact == 2) {
                this.showContact = 0;
            }
        },
        ContactCommu() {
            if (this.showContact == 0 || this.showContact == 1 || this.showContact == 2) {
                this.showContact = 3;
            }
            else if (this.showContact == 3) {
                this.showContact = 0;
            }
        },

        /**
         * Image preview and upload
         * @param e
         */
        showFilePreview(e) {
            let $this = this;

            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }
            this.createImage(files[0]);
        },
        createImage(file) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },

        //Get the DateTime
        getNow: function () {
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date + ' ' + time;
            this.timestamp = dateTime;
        },

        priceDate: function () {
            const today = new Date();
            if ((today.getMonth() + 1) <= 7) {
                this.period = 0;
            }
            else if (((today.getMonth() + 1) >= 8) && ((today.getMonth() + 1) <= 11)) {
                this.period = 1;
            }
        },
    },
})
